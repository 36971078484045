import axios from "axios";
export const axiosInstance = axios.create()

export default class FormsApi {
    static getFormInfo = (formSessionId) => {
        let endpointUrl = "https://api.payro.io" + "/forms/get/"+formSessionId;
        const config = {
        };
        return axiosInstance.get(endpointUrl, config);
    }


    static approveForm = (formSessionId, signedPdfContent = null) => {
        let endpointUrl = "https://api.payro.io" + "/forms/approve/";
        const config = {
            headers: {
                'Content-Type': 'application/json',
            }
        };

        const data = {
            formSessionId,
            signedPdfContent: signedPdfContent || null
        };

        return axiosInstance.post(endpointUrl, data, config);
    }
}
