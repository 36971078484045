import React, { useEffect, useState } from "react";

const PDFViewer = ({ pdfDriveLinkUrl }) => {
    const [isMobile, setIsMobile] = useState(false);
    const [isIOS, setIsIOS] = useState(false);

    useEffect(() => {
        const userAgent = navigator.userAgent;
        const mobileOS = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
        const iOS = /iPhone|iPad|iPod/i.test(userAgent);
        setIsMobile(mobileOS);
        setIsIOS(iOS);
    }, []);

    return (
        <>
            {/* Download Button */}
            <div style={{ textAlign: "center", marginTop: "20px" }}>
                <a
                    href={pdfDriveLinkUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                        textDecoration: "none",
                        display: "inline-block",
                        marginBottom: "10px",
                    }}
                >
                    <button
                        style={{ padding: "10px 20px", fontSize: "16px" }}
                        dir="rtl"
                    >
                        הורד טופס כאן
                    </button>
                </a>
            </div>

            {isMobile && !isIOS ? (
                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                    <iframe
                        src={`https://docs.google.com/viewer?url=${pdfDriveLinkUrl}&embedded=true`}
                        width="100%"
                        height="600px"
                        frameBorder="0"
                    ></iframe>
                </div>
            ) : (
                <iframe
                    src={`${pdfDriveLinkUrl}#zoom=75`}
                    width="100%"
                    height="600px"
                    title="PDF Viewer"
                />
            )}
        </>
    );
};

export default PDFViewer;
