import './App.css';
import React from 'react';
import {
    BrowserRouter as Router,
    Route, Routes,
    useLocation,
} from 'react-router-dom';
import FormPage from "./FormPage";

// Assuming FormPage is imported correctly
// import FormPage from './path-to-FormPage';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function QueryFormPage() {
    let query = useQuery();
    let sessionId = query.get("session_id");
    return <FormPage formSessionId={sessionId} />;
}

function NotFound() {
    return <h2>404 Not Found</h2>;
}

function App() {
    return (
        <Router>
            <div className="App">
                <Routes>
                    <Route path="/form" element={<QueryFormPage />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;
